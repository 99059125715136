import Vue from 'vue';
import VueRouter from 'vue-router';
import $user from '@/utils/protect-route';

const loadComponent = path => () => import(`@/views/${path}.vue`);

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Main',
		component: loadComponent('MainPage/Main')
	},
	{
		path: '/teachers',
		name: 'Teachers',
		component: loadComponent('MainPage/Teachers')
	},
	{
		path: '/courses',
		name: 'Courses',
		component: loadComponent('MainPage/Courses')
	},
	{
		path: '/news',
		name: 'News',
		component: loadComponent('MainPage/News')
	},
	{
		path: '/about',
		name: 'About',
		component: loadComponent('MainPage/About')
	},
	{
		path: '/login',
		name: 'login',
		component: loadComponent('Login')
	},
	{
		path: '/register',
		name: 'register',
		component: loadComponent('Register')
	},
	{
		path: '/beginner',
		name: 'beginner',
		component: loadComponent('beginner/Beginner'),
	},
	{
		path: '/beginner/reading',
		name: 'begReading',
		component: loadComponent('beginner/begReading')
	},
	{
		path: '/beginner/grammar',
		name: 'begGrammar',
		component: loadComponent('beginner/begGrammar')
	},
	{
		path: '/beginner/vocabulary',
		name: 'begVocabulary',
		component: loadComponent('beginner/begVocabulary')
	},
	{
		path: '/beginner/videos',
		name: 'begVideos',
		component: loadComponent('beginner/begVideos')
	},
	{
		path: '/admin-page',
		name: 'adminMain',
		redirect: '/admin-page/tests',
		component: loadComponent('admin/AdminManage'),
		children: [
			{
				path: '/admin-page/tests',
				name: 'tests',
				component: loadComponent('admin/AdminTests')
			},
			{
				path: '/admin-page/users',
				name: 'users',
				component: loadComponent('admin/AdminUsers')
			},
			{
				path: '/admin-page/posts',
				name: 'posts',
				component: loadComponent('admin/AdminPosts')
			},
			{
				path: '/admin-page/reviews',
				name: 'reviews',
				component: loadComponent('admin/AdminReviews')
			},
			//ELEMENTARY
			{
				path: '/admin-page/elementary',
				name: 'AdElementary',
				component: loadComponent('elementary/Elementary')
			},
			{
				path: '/admin-page/beginner',
				name: 'AdBeginner',
				component: loadComponent('beginner/Beginner')
			},
			{
				path: '/admin-page/elementary/videos',
				name: 'AdElemVideos',
				component: loadComponent('elementary/elemVideos')
			},
			{
				path: '/admin-page/elementary/grammar',
				name: 'AdElemGrammar',
				component: loadComponent('elementary/elemGrammar')
			},
			{
				path: '/admin-page/elementary/grammar/:testId',
				name: 'AdElemGrammarTest',
				component: loadComponent('elementary/elemGramById')
			},
			{
				path: '/admin-page/elementary/reading',
				name: 'AdElemReading',
				component: loadComponent('elementary/elemReading')
			},
			{
				path: '/admin-page/elementary/vocabulary',
				name: 'AdElemVocabulary',
				component: loadComponent('elementary/elemVocabulary')
			},
			{
				path: '/admin-page/elementary/reading/:testId',
				name: 'adReadingById',
				component: loadComponent('elementary/elemReadById')
			},
			{
				path: '/admin-page/elementary/vocabulary/:testId',
				name: 'adVocById',
				component: loadComponent('elementary/elemVocById')
			},
			//ELEMENTARY
			///Pred-Intermeddiate
			{
				path: '/admin-page/pre-intermediate',
				name: 'AdPreIntermediate',
				component: loadComponent('pre-intermediate/PreIntermediate')
			},
			{
				path: '/admin-page/pre-intermediate/videos',
				name: 'preVideos',
				component: loadComponent('pre-intermediate/preVideos')
			},
			{
				path: '/admin-page/pre-intermediate/videos/:testId',
				name: 'preVideoById',
				component: loadComponent('pre-intermediate/preVideoById')
			},
			{
				path: '/admin-page/pre-intermediate/grammar',
				name: 'preGrammar',
				component: loadComponent('pre-intermediate/preGrammar')
			},
			{
				path: '/admin-page/pre-intermediate/grammar/:testId',
				name: 'preGrammarTest',
				component: loadComponent('pre-intermediate/preGramById')
			},
			{
				path: '/admin-page/pre-intermediate/reading',
				name: 'preReading',
				component: loadComponent('pre-intermediate/preReading')
			},
			{
				path: '/admin-page/pre-intermediate/vocabulary',
				name: 'preVocabulary',
				component: loadComponent('pre-intermediate/preVocabulary')
			},
			{
				path: '/admin-page/pre-intermediate/reading/:testId',
				name: 'preReadingById',
				component: loadComponent('pre-intermediate/preReadById')
			},
			{
				path: '/admin-page/pre-intermediate/vocabulary/:testId',
				name: 'preVocById',
				component: loadComponent('pre-intermediate/preVocById')
			},
		],
		beforeEnter: (to, from, next) => {
			$user.checkAdmin(next);
		},
	},
	{
		path: '/elementary',
		name: 'elementary',
		redirect: '/elementary',
		component: loadComponent('elementary/Elementary'),
		children: [
			{
				path: '/elementary/videos',
				name: 'elemVideos',
				component: loadComponent('elementary/elemVideos')
			},
			{
				path: '/elementary/grammar',
				name: 'elemGrammar',
				component: loadComponent('elementary/elemGrammar')
			},
			{
				path: '/elementary/grammar/:testId',
				name: 'elemGrammarTest',
				component: loadComponent('elementary/elemGramById')
			},
			{
				path: '/elementary/reading',
				name: 'elemReading',
				component: loadComponent('elementary/elemReading')
			},
			{
				path: '/elementary/vocabulary',
				name: 'elemVocabulary',
				component: loadComponent('elementary/elemVocabulary')
			},
			{
				path: '/pre-intermediate',
				name: 'preIntermediate',
				component: loadComponent('pre-intermediate/PreInterdiate')
			},
		],
		beforeEnter: (to, from, next) => {
			$user.checkStudent(next);
		},
	},
	{ path: '*', redirect: '/' }
	
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const publicPages = [ '/','/teachers', '/courses', '/news', '/about' ];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = localStorage.getItem('user');
	
	if (authRequired && !loggedIn) {
		return next('/');
	}
	
	next();
});

export default router
