import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          dark: {
            primary: "#9e9e9e",
            secondary: "#000000",
            accent: "#8c9eff",
            error: "#ff00ff"
          }
        }
      }
});
