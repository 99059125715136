//import config from 'config';
import { authHeader, handleResponse } from '@/utils/authHeader';
import firebase from 'firebase/app';

export const userService = {

    changePass (id,data) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/edit-pass/${id}`, requestOptions).then(handleResponse);
    },
    deleteUser (id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/?id=${id}`, requestOptions).then(handleResponse);
    },
    //Login Users
    login (username, password) {
        firebase.auth().signInWithEmailAndPassword(username, password).then(handleResponse);
    },


    //Logout user
    logout () {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        window.location.href = '/';

    },
    //Register Users
    register (data) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/auth/register`, requestOptions).then(handleResponse);
    },
    //Register by phone
    register_by_phone (data) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/auth/register-by-phone`, requestOptions).then(handleResponse);
    },
    create(data) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/create`, requestOptions).then(handleResponse);
    },
    //Validate Phone
    validate_phone (data) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/auth/validate-phone`, requestOptions).then(handleResponse);
    },

    //Must be completed

    change_lang (user) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };
        return fetch(`$${process.env.VUE_APP_BASE_URL}/v1/users/edit-language/${user.id}`, requestOptions).then(handleResponse);
    },

    getAll () {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/list`, requestOptions).then(handleResponse);
    },


    getById (id) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/${id}`, requestOptions).then(handleResponse);
    },


    update (user) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/edit/${user.id}`, requestOptions).then(handleResponse);
    },

    upload_avatar (id, file) {
        const formdata = new FormData();
        formdata.append('file', file);
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader() },
            body: formdata
        };

        return fetch(`${process.env.VUE_APP_BASE_URL}/v1/users/upload-avatar/${id}`, requestOptions).then(handleResponse);
    }
};