//import {userService} from '@/_services/user.service'
import router from '@/_router'
import * as firebase from '../firebase'

const user = JSON.parse(localStorage.getItem('user'))

const roles = [
	{
		code: 'ADMIN',
		url: '/admin-page',
	},
	{
		code: 'STUDENT',
		url: '/elementary'
	}
]

const state = {
	user: user ? user : {},
}

const actions = {
	login({  commit}, form) {
		console.log(form);
		firebase.auth.signInWithEmailAndPassword(form.email, form.password).then(res => {
			firebase.usersCollection.doc(res.user.uid).get().then(snapshot => {
				var item = snapshot.data();
				console.log(item);
				localStorage.setItem('user', JSON.stringify(item));
				commit('SET_USER', item);
				const role = roles.find(i => i.code == item.role);
				router.push(role.url);	
			})
		}).catch(err => console.log(err));
	},
	async logout({ commit }) {
		// log user out
		await firebase.auth.signOut()
  
		// clear user data from state
		commit('setUserProfile', {})
  
		// redirect to login view
		router.push('/')
	},
	async signup({ dispatch }, form) {
		// sign user up
		console.log(form)

		const { user } = await firebase.auth.createUserWithEmailAndPassword(form.email, form.password)
		console.log(user);
		// create user object in userCollections
		await firebase.usersCollection.doc(user.uid).set({
			name: form.name,
			title: form.title,
			isActiveted: false,
			role: 'STUDENT'
		})
  
		// fetch user profile and set in state
		dispatch('fetchUserProfile', user)
	},
	async fetchUserProfile({ commit }, user) {
		// fetch user profile
		const userProfile = await firebase.usersCollection.doc(user.uid).get()
  
		// set user profile in state
		commit('SET_USER', userProfile.data())
  
		// change route to dashboard
		// if (router.currentRoute.path === '/login') {
		//   router.push('/')
		// }
	},
}

const mutations = {
	SET_USER(state, data) {
		state.user = data;
	},
	REMOVE_USER(state) {
		state.user = null;
	},
}

export const account = {
	namespaced: true,
	state,
	actions,
	mutations
}
