import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './_router';
import store from './_store';
import VueSocialSharing from 'vue-social-sharing';
import VueYoutube from 'vue-youtube';
// import firebase from "firebase/app";
// import "firebase/analytics";
// import "firebase/auth";
// import "firebase/firestore";
import VueToastr from "vue-toastr";


Vue.use(VueToastr, {
  clickClose: true,
  defaultTimeout: 500
});
Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(VueSocialSharing);
// const firebaseConfig = {
//   apiKey: "AIzaSyAchNDaG0PO3rZkCCukYVrBkifuVyjmNC0",
//   authDomain: "zhyldyzacademy-41c56.firebaseapp.com",
//   projectId: "zhyldyzacademy-41c56",
//   storageBucket: "zhyldyzacademy-41c56.appspot.com",
//   messagingSenderId: "373579431299",
//   appId: "1:373579431299:web:4fb3601f4785eec436cab6",
//   measurementId: "G-KDSY3VNQMZ"
// };
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
