export default {
	checkAdmin (next) {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user.role == 'ADMIN') {
			next();
		} else {
			next('/');
		}
	},
	checkStudent (next) {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user.role == 'STUDENT') {
			next();
		} else {
			next('/');
		}
	},
};
