import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyAchNDaG0PO3rZkCCukYVrBkifuVyjmNC0",
    authDomain: "zhyldyzacademy-41c56.firebaseapp.com",
    databaseURL: "https://zhyldyzacademy-41c56-default-rtdb.firebaseio.com",
    projectId: "zhyldyzacademy-41c56",
    storageBucket: "zhyldyzacademy-41c56.appspot.com",
    messagingSenderId: "373579431299",
    appId: "1:373579431299:web:4fb3601f4785eec436cab6",
    measurementId: "G-KDSY3VNQMZ"
};
firebase.initializeApp(firebaseConfig);
// utils
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage();
// collection references
const usersCollection = db.collection('users')

const bGrammarCollection = db.collection('beginner_grammar')
const bVideosGrammarCollection = db.collection('beginner_grammar_listening')
const bReadingCollection = db.collection('beginner_reading')
const bVideosCollection = db.collection('beginner_videos')
const bVocabularyCollection = db.collection('beginner_vocabulary')
const eGrammarCollection = db.collection('elementary_grammar')
const eVideosGrammarCollection = db.collection('elementary_grammar_videos')

const eReadingCollection = db.collection('elementary_reading')
const eVideosCollection = db.collection('elementary_videos')
const eVocabularyCollection = db.collection('elementary_vocabulary')
const pGrammarCollection = db.collection('preIntermediate_grammar')
const pVideosGrammarCollection = db.collection('preIntermediate_grammar_videos')

const pReadingCollection = db.collection('preIntermediate_reading')
const pVideosCollection = db.collection('preIntermediate_videos')
const pVocabularyCollection = db.collection('preIntermediate_vocabulary')

const postsCollection = db.collection('posts')

const reviewsCollection = db.collection('reviews')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection,
  //beginner
  bGrammarCollection,
  bReadingCollection,
  bVideosCollection,
  bVocabularyCollection,
  bVideosGrammarCollection,
  //beginner
  //elementary
  eGrammarCollection,
  eReadingCollection,
  eVideosCollection,
  eVocabularyCollection,
  eVideosGrammarCollection,
  //elementary
  //preIntermediate
  pGrammarCollection,
  pReadingCollection,
  pVideosCollection,
  pVocabularyCollection,
  pVideosGrammarCollection,
  //preIntermediate
  commentsCollection,
  likesCollection,
  postsCollection,
  reviewsCollection,
  storage
}